// Responsive mixin
// @include breakAt(lg-device) { style goes here }
@mixin breakAt($point) {
  @if $point == xl-device {
    @media (max-width: 1200px){@content;}
 }
  @else if $point == lg-device {
    @media (max-width: 991px){@content;}
 }
   @else if $point == md-device {
    @media (max-width: 767px){@content;}
 }
  @else if $point == sm-device {
    @media (max-width: 575px) {@content;}
 }
 @else if $point == xs-device {
    @media (max-width: 480px) {@content;}
 }
}